import React from "react"
import TutorialsLayout from "../../components/tutorials-layout"
import workingOnItImage from "../../images/working-on-it.png"
import Seo from "../../components/seo"

function StopwatchPage() {
  return (
    <>
      <Seo title="Stopwatch" titleSuffix="Dashtronaut Tutorials" />
      <div className="working-on-it-image">
        <img src={workingOnItImage} alt="" />
      </div>
    </>
  )
}

StopwatchPage.Layout = TutorialsLayout

export default StopwatchPage
